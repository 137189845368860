import React, { useEffect, useState } from "react";
import IAsset from "../../data/models/IAsset";
import DesignerButton from "../../postcard-designer/features/DesignerInterface/shared/DesignerButton";
import { getAsync, postAsync } from "../../helpers/asyncFetch";
import Modal, {
  ModalActions,
  ModalBody,
  ModalTitle,
} from "../../postcard-designer/features/DesignerInterface/shared/Modal";
import { useDropzone } from "react-dropzone";
import tw, { css } from "twin.macro";

interface AssetSelectorProps {
  defaultAsset?: IAsset;
  onSelect: (asset?: IAsset) => void;
  buttonLabel?: string;
}

const styles = {
  assetContainer: [
    tw`flex items-center flex-wrap w-full max-w-full`,
    css`
      max-height: 300px;
      overflow-y: auto;
    `,
  ],
  container: [
    tw`w-full h-32 border-2 border-dashed border-border bg-inputBackground flex flex-col items-center justify-center mb-6 text-text rounded`,
  ],
  heading: [tw`text-lg capitalize font-semibold`],
  body: [tw`text-sm`],
  imageContainer: tw`w-28 mr-4 mb-4`,
  imageWrapper: (isActive: boolean) => [
    tw`w-28 h-28 overflow-clip flex flex-col`,
    isActive && tw`border-2 border-solid border-border bg-hover`,
  ],
  imageName: tw`text-center`,
  image: [tw`w-full max-w-full block mt-auto mb-auto hover:cursor-pointer`],
  previewImageContainer: tw`w-44 h-44 overflow-clip flex flex-col mb-2`,
};

const AssetSelector = ({
  defaultAsset,
  onSelect,
  buttonLabel = "Choose an Image",
}: AssetSelectorProps) => {
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<IAsset | undefined>(
    defaultAsset,
  );
  const [assets, setAssets] = useState<IAsset[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noKeyboard: true,
    // accept: [".svg", ".png", ".jpg", ".bmp", ".jpeg"],
    multiple: false,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [],
      "image/bmp": [],
      "image/svg+xml": [".svg"],
    },
  });

  async function onDrop(acceptedFiles: File[]) {
    const file = acceptedFiles[0];
    const asset = await postAsync<IAsset>(
      `/assets`,
      {
        file: file,
        fileName: file.name,
        isAdmin: false,
        designID: "null",
        isShape: false,
      },
      true,
    );
    if (asset) {
      setAssets([asset, ...assets]);
      setSelectedAsset(asset);
    }
  }

  async function getAssets() {
    const res = await getAsync<IAsset[]>(`/assets/owned`);
    if (res) {
      setAssets(res);
    }
  }

  function handleConfirm() {
    if (selectedAsset) {
      setSelectorOpen(false);
      onSelect(selectedAsset);
    }
  }

  function handleClear() {
    setSelectedAsset(undefined);
    onSelect(undefined);
  }

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    setSelectedAsset(defaultAsset);
  }, [defaultAsset]);

  return (
    <React.Fragment>
      <div>
        {selectedAsset && (
          <div
            css={styles.previewImageContainer}
            onClick={() => setSelectorOpen(true)}
          >
            <img src={selectedAsset.url} css={styles.image} />
          </div>
        )}
        <DesignerButton onClick={() => setSelectorOpen(true)}>
          {buttonLabel}
        </DesignerButton>{" "}
        <DesignerButton
          onClick={handleClear}
          disabled={!selectedAsset}
          css={tw`bg-white text-blue hover:bg-white disabled:bg-white disabled:text-buttonDisabled`}
        >
          Remove Image
        </DesignerButton>
      </div>
      <Modal
        isOpen={selectorOpen}
        onClose={() => setSelectorOpen(false)}
        width={500}
      >
        <ModalTitle>{buttonLabel}</ModalTitle>
        <ModalBody>
          <div>
            <div>
              <div {...getRootProps()} css={styles.container}>
                <h6 css={styles.heading}>Drop Image Here</h6>
                <p css={styles.body}>Or Click Here To Upload</p>
                <input
                  {...getInputProps()}
                  onKeyDown={(e) => e.preventDefault()}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div>
              <h6>My Uploads 2</h6>
              <div css={styles.assetContainer}>
                {assets
                  .filter((x) =>
                    ["png", "jpg", "jpeg", "bmp", "svg"].includes(x.extension),
                  )
                  .map((asset) => (
                    <div css={styles.imageContainer} key={asset.id}>
                      <div
                        css={styles.imageWrapper(
                          selectedAsset?.id === asset.id,
                        )}
                      >
                        <img
                          src={asset.url}
                          css={styles.image}
                          alt={asset.name}
                          onClick={() => setSelectedAsset(asset)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalActions>
          <React.Fragment>
            <DesignerButton onClick={handleConfirm} disabled={!selectedAsset}>
              Confirm Selection
            </DesignerButton>
          </React.Fragment>
        </ModalActions>
      </Modal>
    </React.Fragment>
  );
};

export default AssetSelector;
