import DesignerSizes from "../../data/models/DesignerSizes";

interface ISizes {
  [key: string]: ISafeAreaObject;
}
interface IPage {
  name: string;
  hasAddressBlock: boolean;
  width: number;
  height: number;
  pageNumber: number;
  bleed?: {
    width: number;
    height: number;
    topOffset?: number;
    leftOffset?: number;
  };
  safeArea?: {
    topOffset?: number;
    leftOffset?: number;
    width: number;
    height: number;
  };
  foldLines?: {
    direction: "vertical" | "horizontal";
    number: number;
  };
}

export interface IFoldLine {
  width: number;
  direction: "vertical" | "horizontal";
  label?: string;
}

export interface ISafeAreaObject {
  addressBlock: {
    width: number;
    height: number;
    left: number;
    top: number;
  };
  postage?: {
    width: number;
    height: number;
    left: number;
    top: number;
  };

  safeArea: {
    width: number;
    height: number;
  };
  bleed?: number;
  height: number;
  width: number;
  pages?: IPage[];
}

const Sizes: ISizes = {
  "46": {
    addressBlock: {
      width: 3.945,
      height: 2.5826,
      top: 1.9174,
      left: 2.305,
    },
    bleed: 0.25,
    postage: {
      width: 0.5504,
      height: 0.7336,
      left: 5.3246,
      top: 0.125,
    },
    safeArea: {
      width: 5.75,
      height: 4,
    },
    height: 4.5,
    width: 6.25,
    pages: [
      {
        name: "Front",
        hasAddressBlock: false,
        height: 4.5,
        width: 6.25,
        pageNumber: 0,
      },
      {
        name: "Back",
        hasAddressBlock: true,
        height: 4.5,
        width: 6.25,
        pageNumber: 1,
      },
    ],
  },
  "68": {
    addressBlock: {
      width: 3.945,
      height: 2.5826,
      top: 3.6674,
      left: 4.805,
    },
    bleed: 0.25,
    postage: {
      width: 0.5504,
      height: 0.7336,
      left: 7.8246,
      top: 0.125,
    },
    safeArea: {
      width: 8.25,
      height: 5.75,
    },
    height: 6.25,
    width: 8.75,
    pages: [
      {
        name: "Front",
        hasAddressBlock: false,
        height: 6.25,
        width: 8.75,
        pageNumber: 0,
      },
      {
        name: "Back",
        hasAddressBlock: true,
        height: 6.25,
        width: 8.75,
        pageNumber: 1,
      },
    ],
  },
  "611": {
    bleed: 0.25,
    addressBlock: {
      width: 4.1368,
      height: 2.3928,
      top: 3.7322,
      left: 6.9882,
    },
    postage: {
      width: 0.5504,
      height: 0.7336,
      left: 10.1996,
      top: 0.125,
    },
    safeArea: {
      width: 10.75,
      height: 5.75,
    },
    height: 6.25,
    width: 11.25,
    pages: [
      {
        name: "Front",
        hasAddressBlock: false,
        height: 6.25,
        width: 11.25,
        pageNumber: 0,
      },
      {
        name: "Back",
        hasAddressBlock: true,
        height: 6.25,
        width: 11.25,
        pageNumber: 1,
      },
    ],
  },
  "46S": {
    bleed: 0.25,
    addressBlock: {
      width: 3.2835,
      height: 2.375,
      left: 2.9665,
      top: 1.65,
    },
    safeArea: {
      height: 3.75,
      width: 5.75,
    },
    height: 4.25,
    width: 6.25,
    pages: [
      {
        name: "Front",
        hasAddressBlock: false,
        height: 4.25,
        width: 6.25,
        pageNumber: 0,
      },
      {
        name: "Back",
        hasAddressBlock: true,
        height: 4.25,
        width: 6.25,
        pageNumber: 1,
      },
    ],
  },
  "69": {
    bleed: 0.25,
    addressBlock: {
      width: 4,
      height: 2.375,
      left: 5,
      top: 3.625,
    },
    safeArea: {
      width: 8.75,
      height: 5.75,
    },
    height: 6.25,
    width: 9.25,
    pages: [
      {
        name: "Front",
        hasAddressBlock: false,
        height: 6.25,
        width: 9.25,
        pageNumber: 0,
      },
      {
        name: "Back",
        hasAddressBlock: true,
        height: 6.25,
        width: 9.25,
        pageNumber: 1,
      },
    ],
  },
  "651": {
    bleed: 0.25,
    addressBlock: {
      width: 4,
      height: 2.375,
      left: 7.25,
      top: 3.65,
    },
    safeArea: {
      width: 11,
      height: 6,
    },
    height: 6.25,
    width: 11.25,
    pages: [
      {
        name: "Front",
        hasAddressBlock: false,
        height: 6.25,
        width: 11.25,
        pageNumber: 0,
      },
      {
        name: "Back",
        hasAddressBlock: true,
        height: 6.25,
        width: 11.25,
        pageNumber: 1,
      },
    ],
  },
  "811": {
    addressBlock: {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    },
    safeArea: {
      width: 8,
      height: 10.5,
    },
    height: 11,
    width: 8.5,
  },
  [DesignerSizes.BROCHURE]: {
    addressBlock: {
      width: 6.3432,
      height: 2.1208,
      top: 0,
      left: 0,
    },
    postage: {
      width: 0.5504,
      height: 0.7336,
      left: 5.3246,
      top: 0.125,
    },
    safeArea: {
      width: 10.5,
      height: 8,
    },
    height: 8.5,
    width: 11,
    pages: [
      {
        name: "Front",
        hasAddressBlock: true,
        height: 8.5,
        width: 11,
        pageNumber: 0,
      },
      {
        name: "Back",
        hasAddressBlock: false,
        height: 8.5,
        width: 11,
        pageNumber: 1,
      },
    ],
  },
  [DesignerSizes.SNAP]: {
    addressBlock: {
      width: 4.0269,
      height: 2.52,
      top: 1.1141,
      left: 3.9612,
    },
    postage: {
      width: 0.5346,
      height: 0.7336,
      left: 2.9761,
      top: 0.1797,
    },
    safeArea: {
      width: 7.4764,
      height: 10.75,
    },
    height: 11,
    width: 8.5,
    pages: [
      {
        name: "Front Cover",
        hasAddressBlock: true,
        height: 3.6661,
        width: 8.5,
        pageNumber: 0,
        safeArea: {
          width: 7.4764,
          height: 3.4161,
        },
      },
      {
        name: "Inside Cover",
        hasAddressBlock: false,
        height: 3.6742,
        width: 8.5,
        pageNumber: 1,
        safeArea: {
          width: 7.4764,
          height: 3.4242,
        },
      },
      {
        name: "Back Cover",
        hasAddressBlock: false,
        height: 3.6597,
        width: 8.5,
        pageNumber: 2,
        safeArea: {
          width: 7.4764,
          height: 3.4242,
        },
      },
      {
        name: "Letter",
        hasAddressBlock: false,
        height: 11,
        width: 8.5,
        pageNumber: 3,
        safeArea: {
          width: 7.4764,
          height: 10.75,
        },
      },
    ],
  },
  [DesignerSizes.GREETING_CARD]: {
    addressBlock: {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    },

    safeArea: {
      width: 0,
      height: 0,
    },
    width: 7.25,
    height: 10.25,
    pages: [
      {
        name: "Front",
        hasAddressBlock: false,
        height: 5.125,
        width: 7.25,
        pageNumber: 0,
        safeArea: {
          width: 6.75,
          height: 4.875,
          topOffset: -0.125,
        },
        bleed: {
          width: 0.25,
          height: 0.125,
          topOffset: -0.125 / 2,
          leftOffset: 0,
        },
      },
      {
        name: "Interior",
        hasAddressBlock: false,
        height: 10.25,
        width: 7.25,
        pageNumber: 1,
        safeArea: {
          width: 6.75,
          height: 9.75,
        },
        bleed: {
          width: 0.25,
          height: 0.25,
        },
        foldLines: {
          direction: "horizontal",
          number: 1,
        },
      },
      {
        name: "Back",
        hasAddressBlock: false,
        height: 5.125,
        width: 7.25,
        pageNumber: 2,
        safeArea: {
          width: 6.75,
          height: 4.875,
          topOffset: 0.125,
        },
        bleed: {
          width: 0.25,
          height: 0.125,
          topOffset: 0.125 / 2,
          leftOffset: 0,
        },
      },
    ],
  },
};

export default Sizes;
