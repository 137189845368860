import { useContext, useEffect, useState } from "react";
import QrCode, {
  QrCodeRaw,
  newQrCode,
} from "../../../shared/QrCodeEditor/models/QrCode";
import { getAsync } from "../../../helpers/asyncFetch";
import { QrCodeStylingOptions } from "../../../shared/QrCodeEditor/types/QrCodeStylingTypes";
import tw, { css } from "twin.macro";
import StaticQrCodePreview from "../../../shared/QrCodeEditor/components/StaticQrCodePreview";
import DraggableQrCode from "./components/DraggableQrCode";
import { useDesignerDispatch, useDesignerSelector } from "../../state/store";
import {
  selectQrCode,
  setQrCodeId,
  toggleQrCodeSelector,
} from "../../state/slices/qrCode";
import DesignerButton from "../DesignerInterface/shared/DesignerButton";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import { CanvasContext } from "../../state/contexts/CanvasContext";
import useLayers from "../../hooks/useLayers";
import FabricQrCode from "../../models/types/FabricQrCode";
import asyncClone from "../Canvas/functions/asyncClone";
import generateGuid from "../../../helpers/generateGuid";
import isAdminUser from "../../../helpers/isAdminUser";
import QRCodeStyling from "qr-code-styling";
import blobToBase64 from "../../../helpers/blobToBase64";
import { createCanvasQrCodeObject } from "./functions/createCanvasQrCodeObject";
import { setCurrentTool } from "../../state/slices/toolSettings";
import { Tool } from "../../state/models/ICanvasTool";
import { selectDesignInformation } from "../../state/slices/designInformation";

const styles = {
  section: [tw`mb-4 mt-4`],
  button: [tw`flex items-center w-full justify-center text-base font-semibold`],
  buttonSecondary: [tw`bg-labels`],
  container: [tw`p-4 `],
  dynamicImageWrap: [
    tw`overflow-hidden rounded border-dashed border-4 border-labels inline-block cursor-pointer`,
  ],
};

const QrCodeSidebar = () => {
  const qrCode = useDesignerSelector(selectQrCode);
  const canvas = useContext(CanvasContext);
  const { pages, createLayer } = useLayers();
  const dispatch = useDesignerDispatch();
  const designInformation = useDesignerSelector(selectDesignInformation);
  function checkQrCodeExistsOnPage() {
    if (!canvas) return false;
    const objects = canvas.getObjects();
    return Boolean(objects.find((x) => x.name?.includes("qrcode")));
  }

  async function handleButtonClick(isChange = false, isEdit = false) {
    if (!canvas) return;
    // If Admin, add new qr code template
    // else open the selector
    let existingQrCode: FabricQrCode | undefined;
    pages.forEach((page) => {
      if (!existingQrCode) {
        existingQrCode = page.objects.find((x) =>
          x.name?.includes("qrcode"),
        ) as FabricQrCode | undefined;
      }
    });
    if (existingQrCode && !isChange) {
      const clone = (await asyncClone(existingQrCode)) as FabricQrCode;
      clone.name = `qrcode-${generateGuid()}`;
      clone.__qrCodeId = existingQrCode.__qrCodeId;

      if (canvas) {
        const background = canvas._objects.find((x) => x.name === "background");
        if (background) {
          clone.left = background.left ?? 0;
          clone.top = background.top ?? 0;
          clone.scaleX = 1;
          clone.scaleY = 1;
          canvas.add(clone);
          canvas.renderAll();
          createLayer(clone.name);
        }
      }
    } else if (
      !isAdminUser() ||
      (designInformation.bAccountID && isAdminUser())
    ) {
      dispatch(toggleQrCodeSelector(isEdit ? "edit" : undefined));
    } else {
      const qrCodeConfig = newQrCode.config;
      const obj = await createCanvasQrCodeObject(
        qrCodeConfig,
        canvas,
        -2,
        newQrCode.preview,
      );
      if (obj) {
        canvas.add(obj);
        canvas.renderAll();
        createLayer(obj.name ?? "");
        dispatch(setCurrentTool(Tool.select));
        dispatch(setQrCodeId(-2));
      }
    }
  }
  return (
    <div css={styles.container}>
      <div css={styles.section}>
        {/* Add New Button */}
        <DesignerButton
          css={[styles.button, tw`mb-2`]}
          // disabled={checkQrCodeExistsOnPage()}
          onClick={() => handleButtonClick(false)}
        >
          Add A QR Code <QrCodeIcon width={24} height={24} css={tw`ml-2`} />
        </DesignerButton>
        {/* Edit Button - Doesn't exist if admin either*/}

        {(!isAdminUser() || designInformation.bAccountID) && (
          <DesignerButton
            css={[styles.button, styles.buttonSecondary, tw`mb-2`]}
            onClick={() => handleButtonClick(true, true)}
            disabled={qrCode.qrCodeId === null}
          >
            Edit QR Code Settings
          </DesignerButton>
        )}
        {(!isAdminUser() || designInformation.bAccountID) && (
          <DesignerButton
            css={[styles.button, styles.buttonSecondary]}
            onClick={() => handleButtonClick(true)}
            disabled={qrCode.qrCodeId === null}
          >
            Swap QR Code
          </DesignerButton>
        )}
      </div>
    </div>
  );
};

export default QrCodeSidebar;
