import { fabric } from "fabric";
import { createBleedClipPath } from "../features/Canvas/functions/createBackgroundClipPath";
export function centerAllObjectsOnCanvas2(
  canvas: fabric.Canvas,
  isCanvasCenter = false,
) {
  const currentActiveObject = [...canvas.getActiveObjects()];
  canvas.viewportCenterObject(
    canvas.getActiveObject() ?? canvas.getObjects()[0],
  );
  canvas.discardActiveObject();
  canvas.getObjects().forEach((x) => {
    if (x.name !== "bleed" && x.name !== "background" && x.name !== "overlay")
      x.clipPath = createBleedClipPath(canvas);
  });
  if (currentActiveObject && currentActiveObject.length) {
    canvas.setActiveObject(
      new fabric.ActiveSelection(currentActiveObject, { canvas }),
    );
  }
  canvas.renderAll();
}

export default function centerAllObjectsOnCanvas(
  canvas: fabric.Canvas,
  isCanvasCenter = false,
) {
  const bleed = canvas.getObjects().find((x) => x.name === "bleed");
  if (bleed) {
    const left = bleed.left ?? 0;
    const top = bleed.top ?? 0;
    canvas.viewportCenterObject(bleed);

    canvas.renderAll();
    const leftDiff = bleed.left! - left;
    const topDiff = bleed.top! - top;
    canvas.getObjects().forEach((obj) => {
      if (obj.name !== "bleed") {
        obj.left = obj.left! + leftDiff;
        obj.top = obj.top! + topDiff;
        if (obj.name !== "background" && obj.name !== "overlay") {
          obj.clipPath = createBleedClipPath(canvas);
        }
      }
    });
    canvas.renderAll();
  }
}
